import { template as template_29aea790698b49b2b6037102f43261e2 } from "@ember/template-compiler";
const FKLabel = template_29aea790698b49b2b6037102f43261e2(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
