import { template as template_81e21e4c991346d9b5798668e16578e0 } from "@ember/template-compiler";
const EmptyState = template_81e21e4c991346d9b5798668e16578e0(`
  <div class="empty-state-container">
    <div class="empty-state">
      <span data-test-title class="empty-state-title">{{@title}}</span>
      <div class="empty-state-body">
        <p data-test-body>{{@body}}</p>
      </div>
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default EmptyState;
